.user_mode {

  .hide-this {
    height: 0;
    overflow: hidden;

    .pageElemnt { margin: 0; }

  }

  .no-margin .pageElement { margin: 0; }

  .mobile-view { display: none; }

  .mobile-logo img { max-width: 100px; }

  @media only screen and (max-width: 1024px){

    .mobile-view { display: block; }

  }

  @media only screen and (max-width: 640px){

    .desktop-view { display: none; }

  }

}
